import { UserFragment } from '@types'
import { createContext } from 'react'

const guestUserData: UserFragment & {
  groups: string[] | null
} = {
  __typename: 'User',
  id: '',
  slug: '',
  username: '',
  avatar: {
    __typename: 'UserAvatar',
    color: '',
    content: '',
    image: null,
  },
  isEmployee: false,
  registeredAt: null,
  lastOnlineAt: null,
  groups: [],
  showOfficialBadge: false,
  // canBeMessaged: false,
  canBeMentioned: false,
  canViewProfile: false,
  canStartConversationWith: false,
}

export const guestUserProps: CurrentUserContextShape = {
  id: '',
  data: guestUserData,
  isAdmin: false,
  isEmployee: false,
  isGuest: true,
  isModerator: false,
  isOwner: false,
  isPLM: false,
  isPermittedToStartConversation: false,
  hasAlphaSupport: false,
  hasBetaAccess: false,

  loginUser: () => {},
}

export type CurrentUserFragment = UserFragment & {
  groups: string[] | null
  tags?: string[]
  theme?: string
}

export interface CurrentUserContextShape {
  id: string
  data: CurrentUserFragment
  isAdmin: boolean
  isEmployee: boolean
  isGuest: boolean
  isModerator: boolean
  isOwner: boolean
  isPLM: boolean
  loginUser: () => void
  isLoading?: boolean
  setInterestsOnHold?: () => void
  showInterestsBubble?: boolean
  isPermittedToStartConversation: boolean
  hasAlphaSupport: boolean
  hasBetaAccess: boolean
}
export const CurrentUserContext =
  createContext<CurrentUserContextShape>(guestUserProps)
