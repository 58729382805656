import { SLUG_PLACEHOLDER } from '@core/config'
import { CurrentUserShape } from '@core/contexts/CurrentUserContextV2'
import { UserFragment, UserGroup } from '@types'
import { includes } from 'lodash-es'

export const checkUserGroups = (
  userGroups: string[] | null = null,
  groups: UserGroup[]
): boolean => {
  if (userGroups) {
    for (const group of groups) {
      if (includes(userGroups, group)) {
        return true
      }
    }
  }

  return false
}

export const needAuthorOrEmployee = (
  user: UserFragment,
  authorId: string | null
): boolean => {
  // not logged in
  if (isGuest(user)) {
    return false
  }

  if (user.id === authorId) {
    return true
  }

  if (isAdmin(user.groups || null) || user.isEmployee) {
    return true
  }

  return false
}

export const isOwner = (groups: string[] | null): boolean =>
  checkUserGroups(groups, [UserGroup.Owner])

export const isAdmin = (groups: string[] | null): boolean =>
  checkUserGroups(groups, [UserGroup.Admin])

export const isModerator = (groups: string[] | null): boolean =>
  checkUserGroups(groups, [UserGroup.Admin, UserGroup.Moderator])

export const isPLM = (groups: string[] | null): boolean =>
  checkUserGroups(groups, [UserGroup.PLM])

export const isReleaseContributor = (groups: string[] | null): boolean =>
  checkUserGroups(groups, [UserGroup.ReleaseContributor])

// export const isSupport = (groups: string[] | null | undefined): boolean =>
//   checkUserGroups(groups, [UserGroup.Support]);

export const isGuest = (user: UserFragment | CurrentUserShape): boolean =>
  user.id === ''

export const deletedUser = {
  id: '',
  username: 'Deleted user',
  title: null,
  slug: SLUG_PLACEHOLDER,
  avatar: { color: '#eee', content: '?', image: null },
  isEmployee: false,
  registeredAt: null,
  lastOnlineAt: null,
  groups: [],
  permissions: [],
  showOfficialBadge: false,
  canBeMentioned: false,
  canViewProfile: false,
  canStartConversationWith: false,
}
