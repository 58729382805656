import { ParsedPermissions, PermissionType } from '@types'
import { createContext } from 'react'

export interface PermissionContextShape {
  permissions: ParsedPermissions
  hasPermission(permissionType: PermissionType): boolean
}

export const defaultPermissions: ParsedPermissions = {
  [PermissionType.StoryCreate]: false,
  [PermissionType.StoryMarkAsOfficial]: false,
  [PermissionType.StoryUpdateNonAuthor]: false,
  [PermissionType.StoryUpdate]: false,
  [PermissionType.StoryDelete]: false,
  [PermissionType.StoryDeleteNonAuthor]: false,
  [PermissionType.StoryPublish]: false,
  [PermissionType.StoryFeature]: false,
  [PermissionType.StoryLock]: false,
  [PermissionType.StoryContestDownloadExports]: false,
  // #############################################################################
  [PermissionType.CommentCreate]: false,
  [PermissionType.CommentMarkAsTeam]: false,
  [PermissionType.CommentMarkAsTeamOptional]: false,
  // #############################################################################
  [PermissionType.QuestionCreate]: false,
  [PermissionType.QuestionMarkAsOfficial]: false,
  [PermissionType.QuestionAssign]: false,
  [PermissionType.QuestionFeature]: false,
  [PermissionType.QuestionLock]: false,
  [PermissionType.QuestionRestrictedFields]: false,
  // #############################################################################
  [PermissionType.AnswerCreate]: false,
  [PermissionType.AnswerMarkAsTeam]: false,
  [PermissionType.AnswerMarkAsTeamOptional]: false,
  // #############################################################################
  [PermissionType.ReleaseViewGroups]: false,
  [PermissionType.ReleaseCreateGroups]: false,
  [PermissionType.ReleaseUpdateGroups]: false,
  [PermissionType.ReleaseDeleteGroups]: false,
  [PermissionType.ReleaseViewDrafts]: false,
  [PermissionType.ReleaseCreateDraft]: false,
  [PermissionType.ReleaseMarkAsOfficial]: false,
  [PermissionType.ReleasePublish]: false,
  [PermissionType.ReleaseUpdate]: false,
  [PermissionType.ReleaseDelete]: false,
  [PermissionType.ReleaseFeature]: false,
  [PermissionType.ReleaseLock]: false,
  // #############################################################################
  [PermissionType.ShowOfficialBadge]: false,
  [PermissionType.CanBeMentioned]: false,
  [PermissionType.CanBypassTagLimit]: false,
  [PermissionType.ModeratorNotifications]: false,

  // #############################################################################
  // TODO: List Permisisons that should be removed from returning from response (for now backend returns)
  [PermissionType.ViewRealContentAuthor]: false,
  [PermissionType.ViewUserProfile]: true,
  [PermissionType.ViewExtendedUserProfile]: false,
  [PermissionType.CanStartConversation]: false,
}

const initialPermissionsContext: PermissionContextShape = {
  permissions: defaultPermissions,
  hasPermission: (_permissionType: PermissionType): boolean => false,
}

export const PermissionContext = createContext<PermissionContextShape>(
  initialPermissionsContext
)
