import { useQuery } from '@apollo/client'
import { ErrorBox } from '@components/Error'
import { GetPublicReleaseGroupsDocument, PublicReleaseGroup } from '@types'
import { createContext, useMemo } from 'react'

export interface ReleaseGroupContextShape {
  RELEASE_GROUPS: PublicReleaseGroup[]
}

const BASE_STATE = {
  RELEASE_GROUPS: [],
}

export const ReleaseGroupContext = createContext<ReleaseGroupContextShape>({
  ...BASE_STATE,
})

export const ReleaseGroupContextProvider: React.FC = ({ children }) => {
  const { data, error, loading, refetch } = useQuery(
    GetPublicReleaseGroupsDocument,
    {
      fetchPolicy: 'cache-first',
    }
  )

  const releaseGroups = useMemo(() => {
    if (loading || !data?.publicReleaseGroups) {
      return BASE_STATE
    }

    return { RELEASE_GROUPS: data.publicReleaseGroups }
  }, [data, loading])

  if (error) {
    return <ErrorBox error={error} refetch={refetch} />
  }

  return (
    <ReleaseGroupContext.Provider
      value={{
        ...releaseGroups,
      }}
    >
      {children}
    </ReleaseGroupContext.Provider>
  )
}
