import { ApolloError } from '@apollo/client'
import { ErrorBox as StyledErrorBox } from '@components/ErrorBox'
import { NonEmptyArray } from '@types'
import { isNonEmptyArray } from '@utils/isNonEmptyArray'
import { GraphQLError } from 'graphql'
import * as React from 'react'
import { Slide, toast } from 'react-toastify'

import { ToastContent, ToastType } from './ToastContent'

const GRAPHQL_ERROR_PREFIX = 'GraphQL error: '

const removeGraphqlErrorPrefix = (msg: string): string => {
  return msg.replace(GRAPHQL_ERROR_PREFIX, '')
}

export type MutationError = Error | GraphQLError
type MutationErrors = readonly MutationError[] | MutationError[]

export const throwIfError = (errors: NonEmptyArray<MutationError>): never => {
  throw new Error(getErrorMessage(errors))
}

export const getErrorMessage = (
  errors: NonEmptyArray<MutationError>
): string => {
  return removeGraphqlErrorPrefix(errors[0].message)
}

interface ErrorBoxProps {
  error: ApolloError
  collapsed?: boolean
  padding?: string
  refetch: () => void
}

export const ErrorBox: React.FC<ErrorBoxProps> = ({
  error,
  collapsed,
  padding,
  refetch,
}) => (
  <StyledErrorBox
    title={error.toString()}
    collapsed={collapsed}
    padding={padding}
    actionLabel="Try Again"
    onReload={() => refetch()}
  />
)

export const dismissErrors = (): void => {
  toast.dismiss()
}

export const alertIfErrors = (
  title: string,
  errors: MutationErrors | null | undefined
): void => {
  if (isNonEmptyArray(errors)) {
    toast.error(
      <ToastContent
        title={title}
        messages={errors.map(({ message }) =>
          removeGraphqlErrorPrefix(message)
        )}
        type={ToastType.Error}
      />,
      {
        hideProgressBar: true,
        transition: Slide,
      }
    )
  }
}

export const alertMessages = (title: string, messages: string[]): void => {
  toast.error(
    <ToastContent
      title={title}
      messages={messages.map((message) => removeGraphqlErrorPrefix(message))}
      type={ToastType.Error}
    />,
    { hideProgressBar: true, transition: Slide }
  )
}
