import { Theme, ThemeColorMode } from '@core/config'
import typography from '@ubnt/ui-components/styles/designToken'
import shadow from '@ubnt/ui-components/styles/designToken'
import { DefaultTheme } from 'styled-components'

import { legacyColorsDark, legacyColorsLight } from './legacyPalette'
import { dark as UICPaletteDark, light as UICPaletteLight } from './UICPalette'

const light: DefaultTheme = {
  ...legacyColorsLight,
  ...UICPaletteLight,
  motif: 'light',
} as const

const dark: DefaultTheme = {
  ...legacyColorsDark,
  ...UICPaletteDark,
  motif: 'dark',
} as const

const theme: { [key in ThemeColorMode]: DefaultTheme } = {
  [Theme.Light]: light,
  [Theme.Dark]: dark,
}

const media = {
  mobileSmall: '320px',
  mobileMedium: '375px',
  mobileLarge: '450px',
  mobileXLarge: '564px',
  small: '767px',
  tablet: '834px', // Ipad Pro 11 inch portrait
  tabletLarge: '950px',
  medium: '1024px',
  mediumLarge: '1120px',
  large: '1200px',
  xlarge: '1920px',
}

export const isSmallMediaQuery = `@media (max-width: ${media.small})`

export { typography, media, shadow }

export default theme
